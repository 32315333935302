export const breakpoints = {
  mobileXS: '320px',
  mobileS: '360px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletPortrait: '979px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobile: `(min-width: 0px)`,
  mobileS: `(min-width: ${breakpoints.mobileS})`,
  mobileM: `(min-width: ${breakpoints.mobileM})`,
  mobileL: `(min-width: ${breakpoints.mobileL})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  laptop: `(min-width: ${breakpoints.laptop})`,
  laptopL: `(min-width: ${breakpoints.laptopL})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
};

export const zIndex = {
  default: '1',
  elevated: '2',
  subMenu: '55',
  sidepanel: {
    sidebar: '25',
    overlay: '20',
  },
  carousel: {
    overlay: 2,
    content: 3,
  },
  card: {
    overlay: 2,
    content: 3,
  },
};

export const colors = {
  primary: '#0070f3',
  qantasVeryLightGrey: '#f5f5f5',
  qantasRed: '#e40000',
  qantasGray: '#717171',
  qantasDarkGray: '#393939',
  qantasWhite: '#ffffff',
  qantasBlack: '#000000',
  qantasLightTeal: '#e2f8f7',
  qantasTeal: '#bff5f3',
  qantasPromoText: '#4a4a4a',
  qantasRedGradient: '#d20000',
  qantasRedHover: '#ae0000',
  qantasRedDark: '#870000',
  qantasInfoBlueLight: '#E9F9F9',
  qantasInfoBlue: '#BFF4F2',
  qantasInfoBlueDark: '#00B4C0',
  qantasInfoBlueDarker: '#007E86',
  qantasFountainBlue: '#4FAFBA',
  qantasInfoBayBlue: '#8DE2E0',
  qantasCharcoalDark: '#323232',
  qantasDarkerGrey: '#4D4D4D',
  qantasDarkGrey: '#666666',
  qantasGrey: '#848484',
  qantasLightGrey: '#B2B2B2',
  qantasLighterGrey: '#DADADA',
  qantasLightestGrey: '#E9EBED',
  qantasPorcelain: '#F4F5F6',
  qantasDarkBackground: '#1A1A1A',
  qantasDark: '#262626',
  qantasLightGreen: '#EFF7EB',
  qantasGreen: '#009100',
  alertWarningOrange: '#F4750C',
  alertWarningOrangeLight: '#FCEBCD',
  alertSuccessGreen: '#4BAA3E',
  alertSuccessGreenLight: '#DFF0D8',
  qffStatusBronze: '#E40000',
  qffStatusSilver: '#AAAAAA',
  qffStatusGold: '#BAA67B',
  qffStatusPlatinum: '#323232',
  qffStatusPlatinumOne: '#EBEBEB',
  qffStatusChairmansLounge: '#1E1E1E',
};
