import ReactDOM from 'react-dom';
import EntryPoint from './components/entry-point';
import { theme } from './config';
import { ThemeProvider } from '@emotion/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import envConfig from './lib/envConfig';

import '../node_modules/@qantas/loy-web-components/dist/qreact.min.css';
import './index.css';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: window?.app?.launchDarkly || envConfig.launchDarklyClientID,
    context: {
      "kind": "user",
      "key": "user-key-123abc",
      "name": "Sandy Smith",
      "email": "sandy@example.com"
    },
    options: { /* ... */ },
  });
  ReactDOM.render(
    <ThemeProvider theme={theme}>
     <LDProvider>
       <EntryPoint />
     </LDProvider>
   </ThemeProvider>,
    document.getElementById('app'),
  );
})();