import { breakpoints, zIndex, colors } from './shared';

export default {
  breakpoints,
  colors,
  fonts: {
    regular: 'Ciutadella-Regular, sans-serif',
    medium: 'Ciutadella-Medium, sans-serif',
  },
  zIndex,
};
